.message-container {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: scroll;
  padding: 40px;

  @media screen and (max-width: 1000px) {
    padding-left: 0;
  }

  @media screen and (max-width: 400px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .message-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 40px;
    height: fit-content;
    min-height: calc(100vh - 160px);
    max-width: 1000px;
    background-color: var(--header-bg);
    border-radius: 6px;
    border: 1px solid var(--border);

    h1 {
      font-size: 26px !important;
      color: var(--color-content-text) !important;
      margin: 10px 0px;
    }
    h2 {
      margin: 10px 0px;
      font-size: 22px !important;
      color: var(--color-content-text) !important;
    }
    h3 {
      margin: 10px 0px;
      font-size: 18px !important;
      color: var(--color-content-text) !important;
    }

    p,
    font,
    u,
    span,
    li,
    div {
      padding: 0;
      font-size: 16px !important;
      line-height: 24px !important;
      color: var(--color-content-text) !important;
    }

    > div {
      width: 500px;
      max-width: 90%;
    }

    ul {
      padding-left: 20px;
      color: var(--color-content-text) !important;
    }
  }

  .message-img {
    margin: 0 auto;
    margin-top: 40px;
    height: 300px;
    width: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    @media screen and (max-width: 400px) {
      height: 200px;
    }
  }
}

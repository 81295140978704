.attachments {
  padding: 0px 20px 0px 20px;
  text-align: left;
  color: white;

  ul {
    font-size: 12px;
    margin-left: -6px;
    margin-right: -6px;
    list-style: none;
    padding: 0;

    .attached-file {
      position: relative;
      transition: all 120ms ease 25ms;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 12px);
      margin-bottom: 2px;
      padding: 6px;
      border-radius: 8px;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid transparent;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0);

      &:hover {
        background-color: var(--file-hover);
        border: 1px solid var(--border);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        .file-right {
          display: flex;
        }
      }

      .file-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;

        .extension {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex-grow: 0;
          flex-shrink: 0;
          width: 36px;
          height: 36px;
          border-radius: 8px;
          border: 1px solid var(--border);
          margin-right: 10px;

          img {
            width: 16px;
            height: 16px;
          }
        }
        .text-sec {
          font-size: 13px;
          //
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: auto;
        }
      }

      .file-right {
        display: none;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        right: 6px;
        background: var(--file-right-bg);
        width: 140px;

        .eye-icon {
          color: var(--color-contrast);
          margin-right: 10px;
          width: 15px;
        }

        .btn {
          cursor: pointer;
          svg {
            width: 16px;
            height: 16px;
          }

          &:hover {
            background-color: var(--highlight-hover-2);
          }
        }
      }
    }
  }
}

.info-bar {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 350px;
  height: 100vh;
  text-align: left;
  background-color: var(--header-bg);
  border-left: 1px solid var(--border);
  transition: all 350ms ease 100ms;
  margin-right: 0px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    border-left: inherit;
  }

  .btn-infobar-switch {
    position: absolute;
    transition: all 350ms ease 100ms;
    top: 0;
    bottom: 0;
    right: 350px;
    margin: auto;
    cursor: pointer;
    height: 60px;
    width: 19px;
    background-color: var(--header-bg);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-right: 1px solid var(--header-bg);

    svg {
      transition: all 350ms ease 100ms;
      margin-left: 2px;
      filter: var(--svg-contrast);
      opacity: 0.6;
    }

    &:hover {
      box-shadow: none;
      svg {
        opacity: 1;
      }
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }

    &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      top: -11px;
      right: -1px;
      border-bottom: 1px solid var(--border);
      border-right: 1px solid var(--border);
      border-bottom-right-radius: 11px;
      box-shadow: var(--header-bg) 2px 2px 0px 2px;
      overflow: hidden;
    }
    &::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      bottom: -11px;
      right: -1px;
      border-top: 1px solid var(--border);
      border-right: 1px solid var(--border);
      border-top-right-radius: 11px;
      box-shadow: var(--header-bg) 2px -2px 0px 2px;
      overflow: hidden;
    }
  }

  &.info-bar-closed {
    margin-right: -351px;

    @media screen and (max-width: 1000px) {
      height: 80px;

      .archireport-footer {
        opacity: 0;
        height: 0px;
        pointer-events: none;
      }

      .btn-infobar-switch-mobile {
        svg {
          &.switch-close-mobile {
            display: none;
          }
          &.switch-open-mobile {
            display: block;
          }
        }
      }
      .main-content {
        overflow: hidden;
      }
    }
    @media screen and (min-width: 1000px) {
      .btn-infobar-switch {
        right: 0px;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .btn-infobar-switch-mobile {
    margin-left: auto;

    @media screen and (min-width: 1000px) {
      display: none;
    }
    svg {
      transition: all 350ms ease 100ms;
      color: var(--svg-contrast);
      width: 18px;
      height: 18px;

      &.switch-close-mobile {
        display: block;
      }
      &.switch-open-mobile {
        display: none;
      }
    }
  }

  .main-content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px);
  }

  .subject {
    padding: 32px 20px 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 1000px) {
      padding: 20px 20px 0px 20px;
    }

    .subject-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 1000px) {
        max-width: calc(100% - 60px);

        .subject-infos {
          max-width: calc(100% - 50px);
        }
      }

      img {
        width: 45px;
        height: 37px;
        object-fit: cover;
        border-radius: 3px;
        margin-right: 8px;
      }

      .subject-infos {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 60px);

        p {
          padding: 2px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .date {
    padding: 30px 20px 0px 20px;

    p {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .account-infos {
    padding: 0 20px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .header-image {
        width: 32px;
        height: 32px;
        object-fit: cover;
        background-color: white;
        border-radius: 16px;
        margin-right: 8px;
      }

      > div {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 40px);

        p {
          padding: 2px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .report-download {
    .sign-report {
      margin-bottom: 15px;
    }

    padding: 0 20px;

    a {
      text-decoration: none;

      svg {
        color: var(--color-contrast);
        margin-right: 6px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .archireport-footer {
    display: flex;
    transition: all 350ms ease 100ms;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-top: 1px solid var(--border);
    background-color: var(--footer-bg);
    opacity: 1;

    .footer-logo-dark {
      display: none;
    }
  }

  &.info-bar-reminder {
    .subject {
      .subject-content {
        .subject-infos {
          max-width: 100%;
          p {
            white-space: inherit;
            overflow: inherit;
            text-overflow: inherit;
          }
        }
      }
    }
  }
}

body {
  background-color: var(--body-bg);
}

.App {
  flex-direction: row-reverse;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .App {
    flex-direction: column;
  }
}

:root {
  --body-bg: #f6f6f6;
  --header-bg: #ffffff;
  --border: #e8e8e8;
  --highlight: white;
  --highlight-hover: #fafafa;
  --highlight-hover-2: #fafafa;
  --file-hover: #ffffff;
  --file-right-bg: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  --color-contrast: #000000;
  --color-grey: #80848d;
  --color-white: white;
  --color-content-text: rgb(58, 60, 68);
  --footer-bg: #f8f8f8;
}
@media (prefers-color-scheme: dark) {
  :root {
    --body-bg: #222229;
    --header-bg: #28282d;
    --border: #3d3e45;
    --highlight: #35353c;
    --highlight-hover: #37383d;
    --highlight-hover-2: #3e4046;
    --file-hover: #303036;
    --file-right-bg: linear-gradient(
      90deg,
      rgba(48, 48, 54, 0) 0%,
      rgba(48, 48, 54, 1) 50%,
      rgba(48, 48, 54, 1) 100%
    );
    --color-contrast: white;
    --color-white: white;
    --color-grey: #acadb3;
    --color-content-text: rgb(218, 218, 218);
    --footer-bg: #26262b;
    .archireport-footer {
      .footer-logo-light {
        display: none;
      }
      .footer-logo-dark {
        display: block !important;
      }
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
  background-color: var(--highlight);
  padding: 0;
  border-radius: 5px;
  color: var(--color-contrast);
  transition: all 120ms ease 25ms;

  &.btn-small {
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    }
  }

  &.btn-large {
    width: 100%;
    height: 44px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    &:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    }
  }

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--highlight-hover);
  }
}

p,
a,
span,
font,
h1,
h2,
h3,
h4 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  padding: 0;
  margin: 0;
}

.text-main {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-contrast);
}

.text-sec {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-contrast);
}

.text-regular {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-grey);
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: var(--border);
}

.file {
  display: flex;
  justify-content: center;
  flex: 8;
  height: 100%;
  background-color: transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: red;

  .file-unknown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .extension {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 15px;
      background-color: white;
      border: 1px solid var(--border);

      img {
        width: 26px;
        height: 26px;
      }
    }

    p {
      margin: 0 auto;
      text-align: center;
      margin-top: 28px;
      margin-bottom: 14px;
      color: var(--color-contrast);
      font-weight: 500;
      font-size: 16px;
      padding: 0 20px;
    }

    span {
      color: var(--color-grey);
      font-size: 14px;
      padding: 0 20px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.popup-viewer {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.9);
  text-align: left;
  color: white;

  .extension {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: white;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    padding: 20px 0px 35px 0px;

    .left-part {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;
      padding-left: 24px;
      max-width: calc(100% - 120px);

      .extension {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        margin-right: 10px;

        img {
          width: 16px;
          height: 16px;
        }
      }
      p {
        font-weight: 500;
        color: white;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .right-part {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 24px;

      button {
        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .btn-transparent {
      transition: all 120ms ease 25ms;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      height: 45px;
      border-radius: 8px;
      cursor: pointer;
      border: none;
      padding: 0 10px;

      span {
        font-size: 16px;
        font-weight: 500;
        color: white;
      }

      svg {
        width: 20px;
        height: 20px;
        color: var(--color-white);
        margin-right: 8px;
      }

      &.close-viewer {
        svg {
          width: 15px;
          height: 15px;
        }
      }

      &:hover {
        background: #1d1c1c;
      }

      @media screen and (max-width: 1000px) {
        padding: 0px 3px;
        margin-left: 7px;

        width: 24px;

        span {
          display: none;
        }

        svg {
          width: 21px;
          margin-right: 0px;
        }
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: calc(100vh - 100px);

    .left-arrow,
    .right-arrow {
      display: flex;
      flex: 1;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.hidden {
        visibility: hidden;
      }

      svg {
        opacity: 0.8;
        color: var(--color-white);
        width: 20px;
        height: 20px;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }

    .file {
      .file-unknown {
        margin-top: -5%;
        p {
          color: white;
        }

        span {
          color: rgb(201, 201, 201);
        }
      }
    }
  }
}

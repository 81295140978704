.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-content {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  max-width: 400px;
  margin-top: -5%;
  justify-content: center;
  align-items: center;
  padding: 60px 80px;
  border: 1px solid var(--border);
  background: var(--header-bg);
  color: var(--color-contrast);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;

  @media screen and (max-width: 800px) {
    margin-top: 0%;
    padding: 30px 40px;
  }

  .alert-icon {
    width: 26px;
    height: 26px;
  }

  .text-main {
    font-size: 18px;
    margin-top: 16px;
  }
  .text-regular {
    font-size: 16px;
    max-width: 280px;
    text-align: center;
    line-height: 24px;
    margin-top: 16px;
  }
}
